.form-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 500px;
}

.checkbox-group {
  flex-direction: row;
  align-items: center;
}

.checkbox-group label {
  font-size: 0.825rem;
  margin-left: 10px;
}

.form-group label {
  color: #eee;
  font-weight: bold;
}

.form-group input {
  padding: 12px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #444;
  color: #eee;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  outline: 1px solid 444;
}

.form-group input::placeholder {
  color: #999;
}

.form-group .input-error {
  outline: 1px solid rgb(255, 72, 72);
}

.form-group .error-message {
  color: rgb(255, 72, 72);
  font-size: 0.75rem;
  margin-top: 8px;
}

.form-group select {
  padding: 12px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #444;
  color: #eee;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-group button {
  border: none;
  padding: 10px;
  border-radius: 3rem;
  color: #eee;
  background-color: var(--orange);
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 500ms ease;
}

.form-group button:hover {
  cursor: pointer;
  background-color: var(--gray);
}

.form-group label {
  margin-bottom: 7px;
}

@media screen and (max-width: 768px) {
  .form-group {
    width: 260px;
  }
}
