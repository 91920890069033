.hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: var(--orange);
}

.the-best-ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.the-best-ad > span {
  z-index: 2;
}

.the-best-ad > div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  display: flex;
  gap: 2rem;
  margin-top: 30px;
}

.figures > div {
  display: flex;
  flex-direction: column;
}

.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
  text-align: center;
}
.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}

.hero-button {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}

.hero-button > :nth-child(1) {
  color: white;
  background-color: var(--orange);
  width: 8rem;
  margin-top: 15px;
  border-radius: 3rem;
  transition: all 500ms ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.hero-button > :nth-child(1):hover {
  background-color: var(--gray);
}

.right-h > .btn {
  position: absolute;
  right: 3rem;
  top: 3.7rem;
  color: white;
  background-color: var(--gray);
  border-radius: 3rem;
  transition: all 500ms ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.right-h > .btn:hover {
  color: white;
  background-color: #0245ff;
}

.hero-image {
  position: absolute;
  top: 7.9rem;
  right: 8rem;
  width: 130%;
}
.hero-image-back {
  position: absolute;
  top: 5rem;
  right: 9rem;
  width: 180%;
  z-index: -1;
}

.description {
  display: flex;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: 120px;
  position: absolute;
  top: 35rem;
  right: 30rem;
}

.description > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.description > div > :nth-child(1) {
  color: #b9b7b7;
}
.description > div > :nth-child(2) {
  color: white;
  font-size: 1.4rem;
}

.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}

.hero-social {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem 2rem;
  align-items: start;
  border-radius: 50px;
  position: absolute;
  right: 3.4rem;
  top: 3.2rem;
}
.hero-social:hover {
  background-color: var(--gray);
  transition: all 500ms ease;
}

.hero-social a > img {
  width: 30px !important;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .hero-blur {
    width: 14rem;
  }

  .the-best-ad {
    margin-top: 0.7rem;
    font-size: small;
    align-items: center;
    transform: scale(0.8);
  }

  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text > div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }

  .hero-button {
    justify-content: center;
  }

  .figures {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }
  .figures > div > span:nth-of-type(2) {
    font-size: x-small;
  }

  .right-h {
    margin-top: -4rem;
    position: relative;
    background: none;
  }
  .right-h:nth-child(1) {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .hero-social {
    padding: 0.5rem 1rem;
    left: 1rem;
    top: 6rem;
  }

  .hero-image {
    position: relative;
    width: 15rem;
    left: 5rem;
    top: 6rem;
  }

  .hero-image-back {
    width: 20rem;
    left: -2rem;
    top: 6rem;
  }
}
