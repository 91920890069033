.results {
  padding: 0 2rem;
  display: flex;
  grid-template: 2rem;
}

.left-r {
  flex: 2;
  display: grid;
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.slide-item {
  height: 400px;
  border-radius: 5px;
  gap: 2rem;
}

.slide-description {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  background-color: gray;
  padding: 5px 10px;
  border-radius: 5px;
}

.right-r {
  margin: 0 30px;
  flex: 2;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.right-r > span {
  font-size: 1.3rem;
  font-weight: bold;
  color: #2a62fdfd;
}

.right-r > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 30px;
}

.details-r > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.details-r > div > span {
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
  .results {
    margin-top: -6rem;
    flex-direction: column-reverse;
  }

  .slide-item {
    width: 230px;
    height: 230px;
  }

  .details-r {
    font-size: x-small;
    align-items: center;
    justify-content: center;
  }

  .right-r {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-bottom: 5rem;
  }

  .right-r > span {
    font-size: medium;
    align-self: start;
    font-weight: bold;
    color: #2a62fdfd;
    margin-bottom: 0.3rem;
  }

  .right-r > div {
    color: white;
    font-size: xx-large;
    font-weight: bold;
  }

  .details-r > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .icon-style {
    font-size: 2rem;
  }

  .details-r > div > span {
    font-size: 0.9rem;
  }
}
