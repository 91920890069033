.footer-container {
  position: relative;
  gap: 10rem;
}

.right-fo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-fo > img {
  width: 120px;
  cursor: pointer;
}
