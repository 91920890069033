.feedback {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}

.left-f {
  display: flex;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}

.left-f > :nth-child(1) {
  color: #2a62fdfd;
  font-weight: bold;
}
.left-f > :nth-child(2) {
  font-weight: bold;
  font-size: 3rem;
}

.left-f > :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
  font-size: 1.1rem;
}

.right-f {
  flex: 1;
  position: relative;
}

.right-f > img {
  position: absolute;
  width: 19rem !important;
  right: 8rem;
  top: 2rem;
  border-radius: 5px;
}

.right-f > :nth-child(1) {
  position: absolute;
  width: 19rem;
  height: 20rem;
  border: 2px solid blue;
  background-color: transparent;
  right: 9rem;
  top: 0.5rem;
}

.right-f > :nth-child(2) {
  position: absolute;
  width: 19rem;
  height: 19rem;
  right: 7rem;
  top: 4rem;
  background: linear-gradient(210.41deg, #0c3dc3 1.14%, #5581fa 100.75%);
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 9rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .feedback {
    flex-direction: column;
  }

  .left-f > :nth-child(2) {
    font-size: xx-large;
  }

  .right-f {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .right-f > div {
    position: relative;
    display: none;
  }

  .right-f > img {
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
  }

  .right-f > :last-child {
    display: block;
    bottom: 0;
    left: 0;
  }

  .left-f > :nth-child(4) {
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 30px;
    font-size: 1rem;
  }
}
